.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.containerBackground {
  background-color: #fdfdfd;
  height: 100%;
  min-height: 90vh;
  padding: 6rem 1rem 0 !important;
}

.loaderBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10rem;
}
.paperHeight {
  padding: 1rem;
  overflow: auto;
  max-height: 80vh;
}
.fontBold {
  font-weight: bold !important;
}
.encounterBox {
  margin-top: 1rem;
  background-color: #ebedf7;
  padding: 1rem;
  border-left: 3px solid #2897ec;
  border-radius: 10px;
}
.encounterTitle {
  font-weight: light;
  text-align: right;
}
.encounterCaption {
  text-align: left;
  color: black !important;
}
.text-left {
  margin-top: 1rem;
  text-align: left;
}
.reasonBox {
  background-color: #f0f4c3;
  padding: 0.2rem;
}
.badgeColor {
  font-weight: 300;
  background-color: #2393e8;
  padding: 0.2rem 0.4rem;
  color: white;
  font-size: 0.875rem;
  border-radius: 4px;
}
.primary-color {
  background-color: #0068b7;
  padding: 0.2rem 0.4rem;
  color: white;
  font-size: 0.875rem;
  border-radius: 4px;
}
.secondary-color {
  background-color: #5eb9ff;
  padding: 0.2rem 0.4rem;
  color: white;
  font-size: 0.875rem;
  border-radius: 4px;
}
.tertiary-color {
  background-color: #b1ddfe;
  padding: 0.2rem 0.4rem;
  color: rgb(255, 255, 255);
  font-size: 0.875rem;
  border-radius: 4px;
}
.em-color {
  background-color: #2e7d32;
  padding: 0.2rem 0.4rem;
  color: rgb(255, 255, 255);
  font-size: 0.875rem;
  border-radius: 4px;
}
.flex {
  display: flex;
}
.flex-column{
  flex-direction: column !important;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.appBar {
  background-color: white !important;
  color: black !important;
  padding: 0.2rem 0.5rem;
}
.borderDashed {
  border-style: dashed;
}
.margin-left-2 {
  margin-left: 1rem;
}
.margin-left-1 {
  margin-left: 0.5rem !important;
}
.margin-right-2 {
  margin-right: 1rem !important;
}
.margin-bottom-1 {
  margin-bottom: 1rem;
}
.grey-title-color {
  color: rgb(165, 162, 162) !important;
}
.codesBox {
  padding: 1rem;
  border-radius: 20px;
  border: 1px solid #c9e7ff;
}

/* AddCodeDrawer */

.custom-search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #ccc;
}

.drawer-content {
  width: 100%;
  padding: 16px;
  flex-grow: 1;
}

.drawer-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  flex-grow: 1;
}

.drawer-box-item {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 90%;
  position: relative;
}

.drawer-field {
  border-radius: 4px;
  padding: 8px;
  width: 90%;
}

.drawer-field-content {
  flex-grow: 1;
  overflow-y: auto;
}

.drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #ccc;
}

.font-bold {
  font-weight: bold;
}

.color-gray {
  color: gray;
}

.color-text-secondary {
  color: rgba(0, 0, 0, 0.54);
}

.markdown p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

/* algoliaSearch.tsx */

.custom-search-box .ais-SearchBox-input {
  width: 100%;
}

.hit-component {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: left;
  cursor: pointer;
}

.hit-component-extension {
  font-weight: 300;
  background-color: #004d40;
  padding: 3px 8px;
  color: white;
  font-size: 0.875rem;
  border-radius: 5px;
}

.results-container {
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.ais-SearchBox-submit,
.ais-SearchBox-reset {
  display: none;
}

.ais-Hits-list {
  list-style-type: none;
  padding: 0;
}

.ais-Hits-item {
  margin: 0;
}
.codeCard {
  width: 100%;
  height: 100%;
  min-height: 10rem;
  flex-direction: column;
}
